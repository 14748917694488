<template>
  <div class="commodity-out-detail">
    <div class="header">
      <div class="infomation"
           v-if="$route.params.id">
        <van-cell title="出库单号"
                  :value="detail.deliveryNumber" />
      </div>
      <van-form @submit="onSubmit"
                class="form"
                :class="{ disabled: detail.status == 'SUBMITTED' }"
                ref="form">
        <div>
          <van-field v-model="detail.deliveryType"
                     name="出库类型"
                     label="出库类型"
                     readonly
                     required
                     clickable
                     @click="showWarehousingType = true"
                     placeholder="请选择"
                     :rules="[{ required: true, message: '出库类型不能为空' }]" />
          <van-popup v-model="showWarehousingType"
                     round
                     position="bottom">
            <custom-popup title="出库类型"
                          value-key="value"
                          :columns="deliveryTypeColumns"
                          @cancel="showWarehousingType = false"
                          @confirm="warehousingTypeConfirm"
                          v-if="deliveryTypeColumns.length"></custom-popup>
            <van-empty description="暂无数据"
                       v-if="!deliveryTypeColumns.length" />
          </van-popup>
        </div>
        <div>
          <van-field v-model="detail.organizationName"
                     name="单位"
                     label="单位"
                     readonly
                     clickable
                     @click="showOrganization = true"
                     placeholder="请选择" />
          <van-popup v-model="showOrganization"
                     round
                     position="bottom">
            <custom-popup title="单位"
                          value-key="name"
                          :columns="organizationColumns"
                          @cancel="showOrganization = false"
                          @confirm="organizationNameConfirm"
                          v-if="organizationColumns.length" />
            <van-empty description="暂无数据"
                       v-if="!organizationColumns.length" />
          </van-popup>
        </div>
        <div>
          <van-field v-model="departmentComputed"
                     name="部门"
                     label="部门"
                     readonly
                     clickable
                     @click="showDepartment = true"
                     placeholder="请选择" />
          <van-popup v-model="showDepartment"
                     round
                     position="bottom">

            <custom-popup title="部门"
                          value-key="name"
                          :columns="departmentColumns"
                          @cancel="showDepartment = false"
                          @confirm="departmentConfirm"
                          v-if="departmentColumns.length" />
            <van-empty description="暂无数据"
                       v-if="!departmentColumns.length" />
          </van-popup>
        </div>
        <div>
          <van-field v-model="warehouseComputed"
                     name="仓库名称"
                     label="仓库名称"
                     readonly
                     required
                     clickable
                     @click="showWarehouse = true"
                     placeholder="请选择"
                     :rules="[{ required: true, message: '仓库名称不能为空' }]" />
          <van-popup v-model="showWarehouse"
                     round
                     position="bottom">
            <custom-popup title="仓库"
                          value-key="name"
                          :columns="warehouseColumns"
                          @cancel="showWarehouse = false"
                          @confirm="warehouseConfirm"
                          v-if="warehouseColumns.length" />
            <van-empty description="暂无数据"
                       v-if="!warehouseColumns.length" />
          </van-popup>
        </div>
        <div class="action">
          <van-button class="btn"
                      round
                      block
                      type="info"
                      native-type="button"
                      :disabled="detail.status == 'SUBMITTED'"
                      @click="save('SUBMITTED')">提交</van-button>
          <van-button class="btn"
                      round
                      block
                      type="info"
                      native-type="button"
                      :disabled="detail.status == 'SUBMITTED'"
                      @click="save('STAGING')">暂存</van-button>
          <van-button class="btn"
                      round
                      block
                      type="danger"
                      native-type="button"
                      :disabled="detail.status == 'SUBMITTED'"
                      @click="del"
                      v-if="$route.params.id">删除</van-button>
        </div>
      </van-form>
    </div>
    <div class="main"
         :class="{ disabled: detail.status == 'SUBMITTED' }">
      <div class="detail">
        <item-card title="物品出库明细">
          <template slot="status">
            <span class="btn"
                  @click="storehouse=true">添加</span>
          </template>
          <!-- <div class="action">
            <span class="btn"
                  @click="manualAdd">手动添加</span>
            <span class="btn"
                  @click="scanAdd">扫码添加</span>
          </div> -->
          <div class="list">
            <div class="list-item"
                 v-for="(item, index) in detail.items"
                 :key="item.id"
                 @click="itemClick(item, index)">
              <div class="fields">{{ item.barcode }} - {{ item.name }}</div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">规格型号:</span>
                  <span class="field-value">{{ item.specifications }}</span>
                </div>
              </div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">数量:</span>
                  <span class="field-value">{{ item.deliveryQuantity }}</span>
                </div>
              </div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">存放货位:</span>
                  <span class="field-value">{{ item.stockSite }} - {{ item.code }}</span>
                </div>
              </div>
              <div class="fields">
                <div class="field">
                  <span class="field-name">单价:</span>
                  <span class="field-value">{{ item.unitPrice | numberToFixed }}</span>
                </div>
              </div>
              <div class="delete-icon"
                   @click.stop="deleteCommodity(item, index)">
                <van-icon name="cross" />
              </div>
            </div>
          </div>
          <van-empty description="暂无数据"
                     v-if="!detail.items.length" />
        </item-card>
      </div>

      <van-popup class="dialog"
                 v-model="showDialog"
                 :close-on-click-overlay="false">
        <van-form ref="dialogForm">
          <!-- <van-field v-model="dialog.name" name="物品" label="物品" readonly /> -->
          <span class="dialog-title">{{ dialog.name }}</span>
          <van-field v-model="dialog.stockSite"
                     name="存放货位"
                     label="存放货位"
                     required
                     readonly
                     placeholder="请选择"
                     @click="stockSiteClick"
                     :rules="[{ required: true, message: '存放货位不能为空' }]">
          </van-field>
          <van-field v-model="dialog.deliveryQuantity"
                     name="出库数量"
                     label="出库数量"
                     required
                     placeholder="请填写"
                     type="digit"
                     @input="deliveryQuantityInput"
                     :rules="[{ required: true, message: '入库数量不能为空' }]" />
          <div class="action">
            <van-button class="btn"
                        type="info"
                        round
                        size="small"
                        @click="dialogConfirm">确定</van-button>
            <van-button class="btn"
                        type="default"
                        round
                        size="small"
                        native-type="button"
                        @click="dialogCancel">取消</van-button>
          </div>
        </van-form>
      </van-popup>
      <van-popup v-model="showStockSite"
                 round
                 position="bottom">
        <custom-popup title="货位"
                      value-key="stockSite"
                      :columns="stockSiteColumns"
                      @cancel="showStockSite = false"
                      @confirm="stockSiteConfirm"
                      v-if="stockSiteColumns.length">
          <template #option="{option}">
            <span>{{ option.name }} - {{ option.code }} ({{ option.inventoryNumber }}/{{ option.maxCapacity }})</span>
          </template>
        </custom-popup>
        <van-empty description="暂无数据"
                   v-if="!stockSiteColumns.length" />
      </van-popup>

      <van-popup v-model="showCommodityPage"
                 position="right"
                 :style="{ width: '100%', height: '100%' }">
        <div class="add-commodity-page">
          <div class="header">
            <div class="search-type">
              <div class="search"
                   :class="{ actived: searchAreaValue }"
                   @click="commoditySearchClick">
                <div class="search-area">
                  <van-icon name="search" />
                  <span>{{ searchAreaValueComputed }}</span>
                </div>
                <div class="clear"
                     v-if="searchAreaValue"
                     @click.stop="clear(0)">
                  <van-icon name="cross" />
                </div>
              </div>
              <div class="type"
                   :class="{ actived: commodityType }"
                   @click="commodityTypeClick">
                <span class="type-area">{{ commodityTypeComputed }}</span>
                <div class="clear"
                     @click.stop="clear(1)"
                     v-if="commodityType">
                  <van-icon name="cross" />
                </div>
              </div>
            </div>
          </div>
          <div class="main">
            <van-list class="commodity-list"
                      :immediate-check="false"
                      v-model="listStatus.loading"
                      :finished="listStatus.finished"
                      @load="onLoad">
              <div class="commodity-list-item"
                   :class="{ disabled: item.disabled }"
                   v-for="item in commodityList"
                   :key="item.id"
                   @click="commodityItemClick(item)">
                <div class="fields">
                  <div class="name">{{ item.name }}</div>
                  <div class="manufacturer">{{ item.manufacturer }}</div>
                </div>
                <div class="fields">
                  <div class="code">{{ item.code }}</div>
                </div>
              </div>
              <template #finished>
                <div v-if="commodityList.length">没有更多了</div>
              </template>
            </van-list>
            <van-empty description="暂无数据"
                       v-if="!commodityList.length" />
          </div>
          <div class="action">
            <van-button class="btn"
                        type="info"
                        round
                        @click="pageBack">返回</van-button>
          </div>

          <van-popup v-model="showComoditySearch"
                     position="top"
                     round
                     :style="{ paddingTop: '51.78px' }">
            <div class="search-wrap">
              <div class="title">搜索物品名称</div>
              <div class="search-input">
                <input class="search-input__content"
                       ref="searchInput"
                       v-model="searchContent"
                       type="text"
                       placeholder="请输入" />
              </div>
              <div class="search-action">
                <van-button class="search-btn"
                            type="info"
                            size="small"
                            round
                            @click="searchBtnClick">搜索</van-button>
              </div>
            </div>
          </van-popup>
          <van-popup v-model="showComodityType"
                     position="top"
                     round
                     :style="{ paddingTop: '51.78px' }">
            <div class="type-wrap">
              <div class="title">物品分类</div>
              <div class="commodity-type">
                <van-collapse class="commodity-type-item"
                              v-model="activeNames"
                              accordion>
                  <van-collapse-item :name="index"
                                     v-for="(item, index) in commodityTypeList"
                                     :key="item.id">
                    <template #title>
                      <div @click.stop="commodityTypeItemClick(item)">
                        <span>{{ item.name }}</span>
                      </div>
                    </template>
                    <div class="type-list"
                         v-if="item.stockItemCategorys.length">
                      <div class="type-list-item"
                           v-for="i in item.stockItemCategorys"
                           :key="i.id"
                           @click.stop="commodityTypeItemClick(i)">{{ i.name }}</div>
                    </div>
                    <div class="none-data"
                         v-if="!item.stockItemCategorys.length">暂无数据</div>
                  </van-collapse-item>
                </van-collapse>
              </div>
            </div>
          </van-popup>
        </div>
      </van-popup>

      <van-action-sheet v-model="storehouse"
                        :actions="storehouseActions"
                        @select="storehouseSelect"></van-action-sheet>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityOutOfStockDetail.less";
import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";
import { flatMap, find, assign, cloneDeep, debounce } from "lodash-es";
import wxapi from "@/lib/wechat";
export default {
  name: "CommodityOutOfStockDetail",
  data() {
    return {
      detail: {
        deliveryType: "",
        // organization: "",
        organizationName: "",
        departmentId: "",
        supplierName: "",
        warehouseId: "",
        items: []
      },
      deletedCollection: [],

      showWarehousingType: false,
      deliveryTypeColumns: [],
      showOrganization: false,
      organizationColumns: [],
      showDepartment: false,
      departmentColumns: [],
      showSupplier: false,
      supplierColumns: [],
      showWarehouse: false,
      warehouseColumns: [],

      showDialog: false,
      dialog: {
        name: "",
        code: "",
        stockSite: "",
        maxCapacity: "",
        deliveryQuantity: "",
        unitPrice: ""
      },
      stockSiteType: true,
      showStockSite: false,
      stockSiteColumns: [],

      showSearch: false,
      showCommodityPage: false,
      showComoditySearch: false,
      showComodityType: false,
      activeNames: [],
      commodityType: null,
      searchContent: "",
      searchAreaValue: "",
      commodityTypeList: [],
      commodityList: [],
      listStatus: {
        loading: false,
        finished: false
      },
      page: {
        pageNumber: 0,
        pageSize: 10
      },

      storehouse: false,
      storehouseActions: [
        { name: "手动添加", key: "manualAdd" },
        { name: "扫码添加", key: "scanAdd" }
      ]
    };
  },
  computed: {
    departmentComputed() {
      if (!this.departmentColumns.length) return "";
      let item = find(this.departmentColumns, item => item.id == this.detail.departmentId);
      return item ? item.name : "";
    },
    warehouseComputed() {
      if (!this.warehouseColumns.length) return "";
      let item = find(this.warehouseColumns, item => item.id == this.detail.warehouseId);
      return item ? item.name : "";
    },
    searchAreaValueComputed() {
      return this.searchAreaValue ? this.searchAreaValue : "物品搜索";
    },
    commodityTypeComputed() {
      if (!this.commodityTypeList.length) return "物品类别";
      let item = find(this.flatCommodityType, item => item.id == this.commodityType);
      return item ? item.name : "物品类别";
    }
  },
  methods: {
    async getWarehousingDetail() {
      let ret = await this.$api.getStockWarehousingDetail({
        placeholder: {
          id: this.$route.params.id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      this.detail = data;
    },
    async getStockDeliveryDetail() {
      let ret = await this.$api.getStockDeliveryDetail({
        placeholder: {
          id: this.$route.params.id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      this.detail = data;
    },
    async getOrganizationList() {
      let ret = await this.$api.getOrganizationList();
      if (ret.code != SUCCESS_CODE) return;
      this.organizationColumns = ret.data;
    },
    async getOutOfStockType() {
      let query = "name[sin]出库类型";
      let ret = await this.$api.getDictsList({
        params: {
          query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.deliveryTypeColumns = ret.data["出库类型"][0].dictOptions;
    },
    async getDepartmentList(id) {
      let ret = await this.$api.getDepartmentList({
        params: {
          organizationId: id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.departmentColumns = ret.data;
    },
    async getStockSiteList(warehouseId, stockItemId) {
      let params = {
        warehouseId,
        stockItemId
      };
      let ret = await this.$api.getStockSiteList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.stockSiteColumns = ret.data;
    },
    async getWarehouseStockSiteList({ stockItemId: stockItemId = null, warehouseId, query: query = "(status=SUCCESS)" }) {
      let params = {
        pageNumber: 0,
        pageSize: 1000,
        stockItemId,
        sort: encodeURI(`[{"fieldName":"code","direction":"asc"}]`)
      };
      params.query = query;
      return this.$api.getWarehouseStockSiteList({
        params,
        placeholder: {
          id: warehouseId
        }
      });
    },
    addStockDelivery(status) {
      let items = this.detail.items.map(item => {
        return {
          stockItemId: item.stockItemId,
          deliveryQuantity: item.deliveryQuantity,
          status,
          stockSite: item.stockSite,
          warehouseStockRackId: item.warehouseStockRackId
        };
      });
      let params = {
        deliveryType: this.detail.deliveryType,
        organizationName: this.detail.organizationName,
        departmentId: this.detail.departmentId,
        departmentName: this.departmentComputed,
        warehouseId: this.detail.warehouseId,
        status,
        items
      };
      return this.$api.addStockDelivery({
        params
      });
    },
    setStockDelivery(status) {
      let items = this.detail.items.map(item => {
        return {
          id: item.id,
          stockItemId: item.stockItemId,
          deliveryQuantity: item.deliveryQuantity,
          status,
          stockSite: item.stockSite,
          warehouseStockRackId: item.warehouseStockRackId
        };
      });
      let params = {
        deliveryType: this.detail.deliveryType,
        organizationName: this.detail.organizationName,
        departmentId: this.detail.departmentId,
        departmentName: this.departmentComputed,
        warehouseId: this.detail.warehouseId,
        status,
        items
      };
      return this.$api.setStockDelivery({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },
    deleteStockWarehouingItems(ids) {
      let params = {
        ids: ids.join(",")
      };
      return this.$api.deleteStockWarehouingItems({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },
    deleteStockWarehouings() {
      let ids = this.$route.params.id;
      let params = {
        ids
      };
      return this.$api.deleteStockWarehouings({ params });
    },
    getStockItemList({ name = null, barcode = null, categoryId = null, warehouseId = null }, isPagination = true) {
      let params;
      if (isPagination) {
        params = {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
          categoryId,
          name,
          barcode,
          warehouseId
        };
      } else {
        params = {
          page: false
        };
        let query = [{ key: "status", value: "ENABLE", oper: "equalTo" }];
        if (name) query.push({ key: "name", value: name, oper: "equalTo" });
        if (barcode) query.push({ key: "barcode", value: barcode, oper: "equalTo" });
        if (categoryId) query.push({ key: "categoryId", value: categoryId, oper: "equalTo" });
        if (warehouseId) query.push({ key: "warehouseId", value: warehouseId, oper: "equalTo" });
        params.query = this.getQuery(query);
      }
      return this.$api.getStockItemList({ params });
    },
    async getCommodityList(sign = false) {
      let payload = {
        warehouseId: this.detail.warehouseId,
        categoryId: this.commodityType,
        name: this.searchContent != "" ? this.searchContent : null
      };
      let ret = await this.getStockItemList(payload);
      if (ret.code != SUCCESS_CODE) return;
      ret.data.forEach(item => {
        let commodity = find(this.detail.items, i => i.stockItemId == item.id);
        // let commodity = this.detail.items.map(item => item.id).includes(item.id);
        item.disabled = commodity ? true : false;
      });
      if (sign) {
        this.commodityList = this.commodityList.concat(ret.data);
      } else {
        this.commodityList = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery(query) {
      let filters = query.reduce((prev, item) => {
        let filter = Filter.attribute(item.key)[item.oper](item.value);
        prev.push(filter);
        return prev;
      }, []);
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    async getStockItemType() {
      let ret = await this.$api.getStockItemType();
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      this.commodityTypeList = data;
      this.flatCommodityType = flatMap(data, item => {
        return [item, ...item.stockItemCategorys];
      });
    },
    async getWarehouseList() {
      let params = {
        // useType: "WAREHOUSE_KEEPER"
        useType: "WAREHOUSE_MANAGER"
      };
      let ret = await this.$api.getWarehouseList({ params });
      if (ret.code != SUCCESS_CODE) return;
      this.warehouseColumns = ret.data;
    },
    async getSupplierList() {
      let query = "name[sin]来源单位";
      let ret = await this.$api.getDictsList({
        params: {
          query,
          page: false
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.supplierColumns = ret.data["来源单位"][0].dictOptions;
    },
    warehousingTypeConfirm(arg) {
      this.detail.deliveryType = arg.value;
      this.showWarehousingType = false;
    },
    supplierConfirm(arg) {
      this.detail.supplierName = arg.value;
      this.showSupplier = false;
    },
    organizationNameConfirm(arg) {
      this.detail.organizationName = arg.name;
      this.showOrganization = false;
      this.getDepartmentList(arg.id);
    },
    departmentConfirm(arg) {
      this.detail.departmentId = arg.id;
      this.showDepartment = false;
    },
    warehouseConfirm(arg) {
      this.detail.warehouseId = arg.id;
      this.showWarehouse = false;
    },
    deliveryQuantityInput(value) {
      if (!this.dialog.stockSite) {
        this.dialog.deliveryQuantity = "";
        return this.$utils.warning("请先选择货位");
      }
      value = value.replaceAll(/^0+/g, "");
      this.dialog.deliveryQuantity = value;
      if (value > this.dialog.inventoryNumber) {
        this.dialog.deliveryQuantity = "";
        return this.$utils.warning("出库数量不可大于库存数量");
      }
    },
    dialogConfirm() {
      this.$refs.dialogForm
        .validate()
        .then(() => {
          let item = this.detail.items[this.dialogIndex];
          assign(item, this.dialog);
          this.showDialog = false;
        })
        .catch(() => {});
    },
    dialogCancel() {
      this.dialog = cloneDeep(this.dialogCopy);
      this.$refs.dialogForm.resetValidation();
      this.showDialog = false;
    },
    deleteCommodity(item, index) {
      if (item.id) {
        this.deletedCollection.push(item.id);
      }
      this.detail.items.splice(index, 1);
    },
    manualAdd() {
      if (!this.detail.warehouseId) {
        return this.$utils.warning("请先选择仓库");
      }
      this.showCommodityPage = true;
      this.getInitialData();
    },
    getInitialData() {
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      // this.commodityList = [];
      this.getCommodityList();
    },
    pageBack() {
      this.showCommodityPage = false;
    },
    getCode() {
      return new Promise(resolve => {
        wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode", "barCode"],
          success: res => {
            var result = res.resultStr;
            var code = result.split(",")[1];
            resolve(code);
          }
        });
      });
    },
    async scanAdd() {
      if (!this.detail.warehouseId) {
        return this.$utils.warning("请先选择仓库");
      }
      this.getCode().then(async code => {
        let payload = {
          barcode: code
        };
        let ret = await this.getStockItemList(payload, false);
        if (ret.code != SUCCESS_CODE) return;
        if (!ret.data.length) {
          return this.$utils.warning("当前物品目录没有此物品");
        } else {
          let isExist = this.detail.items.find(item => item.stockItemId == ret.data[0].id);
          if (isExist) {
            this.$utils.warning("不可添加重复的物品");
          } else {
            ret.data[0].stockItemId = ret.data[0].id;
            this.detail.items.push(...ret.data);

            let lastOneIndex = this.detail.items.length - 1;
            this.itemClick(this.detail.items[lastOneIndex], lastOneIndex);
          }
        }
      });
    },
    async itemClick(item, index) {
      if (!this.detail.warehouseId) {
        this.$utils.warning("请先选择仓库");
        return;
      }
      this.dialog = cloneDeep(this.dialogCopy);
      this.dialogIndex = index;
      this.showDialog = true;
      assign(this.dialog, item);

      // this.getStockSiteList(this.detail.warehouseId, item.stockItemId);
      let ret = await this.getWarehouseStockSiteList({ warehouseId: this.detail.warehouseId, stockItemId: item.stockItemId });
      if (ret.code != SUCCESS_CODE) return;
      this.stockSiteColumns = ret.data;

      if (this.stockSiteColumns[0]) this.stockSiteConfirm(this.stockSiteColumns[0]);
    },
    stockSiteClick() {
      this.showStockSite = true;
    },
    stockSiteConfirm(arg) {
      this.dialog.stockSite = arg.name;
      this.dialog.code = arg.code;
      this.dialog.maxCapacity = arg.maxCapacity;
      this.dialog.inventoryNumber = arg.inventoryNumber;
      this.dialog.warehouseStockRackId = arg.id;
      this.dialog.deliveryQuantity = "";
      this.showStockSite = false;
    },
    commoditySearchClick() {
      this.showComoditySearch = !this.showComoditySearch;
      this.showComodityType = false;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },

    commodityTypeClick() {
      this.showComodityType = !this.showComodityType;
      this.showComoditySearch = false;
    },
    searchBtnClick() {
      this.showComoditySearch = false;
      this.searchAreaValue = this.searchContent;
      this.getInitialData();
    },
    commodityTypeItemClick(item) {
      this.commodityType = item.id;
      this.showComodityType = false;
      this.getInitialData();
    },
    clear(arg) {
      if (arg) {
        this.commodityType = null;
        this.showComodityType = false;
      } else {
        this.searchAreaValue = "";
        this.searchContent = "";
        this.showComoditySearch = false;
      }
      this.getInitialData();
    },
    commodityItemClick: debounce(
      function (arg) {
        if (arg.disabled) return;
        let item = find(this.detail.items, i => i.stockItemId == arg.id);
        if (!item) {
          arg.stockItemId = arg.id;
          delete arg.id;
          this.detail.items.push(arg);

          let lastOneIndex = this.detail.items.length - 1;
          this.itemClick(this.detail.items[lastOneIndex], lastOneIndex);
        }
        this.showCommodityPage = false;
      },
      300,
      { leading: true, trailing: false }
    ),
    onLoad() {
      this.getCommodityList(true);
    },
    onSubmit() {
      console.log("submit");
    },
    check() {
      if (!this.detail.items.length) {
        this.$utils.warning("请至少添加一个物品");
        return false;
      }
      let result = this.detail.items.some(item => {
        return !item.deliveryQuantity || !item.stockSite;
      });
      if (result) {
        this.$utils.warning("请填写物品完整信息");
        return false;
      }
      return true;
    },
    save(status) {
      this.$refs.form
        .validate()
        .then(async () => {
          if (!this.check()) return;
          if (this.$route.params.id) {
            if (this.detail.status == "SUBMITTED") return;

            if (this.deletedCollection.length) {
              let ret = await this.deleteStockWarehouingItems(this.deletedCollection);
              if (ret.code != SUCCESS_CODE) return;
            }
            let ret = await this.setStockDelivery(status);
            if (ret.code != SUCCESS_CODE) return;
            this.$utils.success(`暂存成功`);
            setTimeout(async () => {
              await this.$router.go(-1);

              setTimeout(() => {
                window.scrollTo(0, 0);
                this.$parent.$refs[window.currentRoute].init();
              }, 500);
            }, 750);
          } else {
            let ret = await this.addStockDelivery(status);
            if (ret.code != SUCCESS_CODE) return;
            this.$utils.success(`${status == "SUBMITTED" ? "提交" : "暂存"}成功`);
            setTimeout(async () => {
              await this.$router.go(-1);

              setTimeout(() => {
                window.scrollTo(0, 0);
                this.$parent.$refs[window.currentRoute].init();
              }, 500);
            }, 750);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    del() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          // on confirm
          let ret = await this.deleteStockWarehouings();
          if (ret.code != SUCCESS_CODE) return;
          this.$utils.success("删除成功");
          setTimeout(async () => {
            await this.$router.go(-1);

            setTimeout(() => {
              window.scrollTo(0, 0);
              this.$parent.$refs[window.currentRoute].init();
            }, 500);
          }, 750);
        })
        .catch(() => {
          // on cancel
        });
    },
    back() {
      setTimeout(async () => {
        await this.$router.go(-1);

        setTimeout(() => {
          window.scrollTo(0, 0);
          this.$parent.$refs[window.currentRoute].init();
        }, 500);
      }, 750);
    },
    storehouseSelect(item) {
      this.storehouse = false;
      this[item.key]();
    }
  },
  mounted() {
    wxapi.ready(["scanQRCode"]).then(res => {
      console.log(res);
    });
  },
  created() {
    this.dialogCopy = cloneDeep(this.dialog);
    this.getOutOfStockType();
    this.getWarehouseList();
    this.getSupplierList();
    this.getStockItemType();
    this.getOrganizationList();
    if (this.$route.params.id) {
      this.getStockDeliveryDetail();
    }
  }
};
</script>
